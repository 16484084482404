var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-container',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters},on:{"update:filters":[function($event){_vm.filters=$event},_vm.updateFilters]},scopedSlots:_vm._u([{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filters',{attrs:{"fields":_vm.headers,"busy":busy,"list-filters":_vm.listFilters},on:{"input":_vm.updateFilters},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{staticClass:"orders-table",attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-action":_vm.isSuperAdmin},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('identifier',{attrs:{"item":item,"get-id":_vm.getIdFromIri,"show-edit-link":_vm.isSuperAdmin || _vm.isCompanyAdmin,"route-type":_vm.routeType}}),(
                            _vm.isSuperAdmin &&
                            ((item.deliveryJob &&
                                item.deliveryJob.warning) ||
                                item.warning)
                        )?_c('warning',{attrs:{"item":item},on:{"execute":function($event){return _vm.removeWarning(item.id)}}}):_vm._e(),_c('deliverect-chips',{attrs:{"item":item}})]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [_c('status',{attrs:{"item":item}})]}},{key:"item.deliveryJob",fn:function(ref){
                        var item = ref.item;
return [_c('delivery-status',{attrs:{"item":item}})]}},{key:"item.deliveryJob.pickupTime",fn:function(ref){
                        var item = ref.item;
return [(_vm.isSuperAdmin)?_c('router-link',{attrs:{"to":_vm.getIdFromIri(
                                item,
                                'restaurant',
                                _vm.routeType.RESTAURANT_EDIT,
                                'id'
                            )}},[_vm._v(" "+_vm._s(item.restaurantName)+" ")]):[_vm._v(" "+_vm._s(item.restaurantName)+" ")],(
                            item.deliveryJob &&
                            item.deliveryJob.primaryMethod !==
                                'restaurant_managed'
                        )?_c('small',{staticClass:"d-flex flex-nowrap g-73--text"},[_vm._v(" "+_vm._s(_vm._f("transformDate")(item.deliveryJob.pickupTime))+" ")]):_vm._e(),_c('third-party-warning',{attrs:{"item":item}})]}},{key:"item.requiredDeliveryTime",fn:function(ref){
                        var item = ref.item;
return [_c('drop-off',{attrs:{"item":item}})]}},{key:"item.deliveryName",fn:function(ref){
                        var item = ref.item;
return [_c('customer',{attrs:{"item":item}})]}},{key:"item.priceTotal",fn:function(ref){
                        var item = ref.item;
return [_c('payment',{attrs:{"item":item}})]}},{key:"item.sent",fn:function(ref){
                        var item = ref.item;
return [_c('flag',{attrs:{"active":parseFloat(item.sentRatio || 0) >= 1}})]}},{key:"item.costCode",fn:function(ref){
                        var item = ref.item;
return [_c('multi-cost-code-col',{attrs:{"item":item},on:{"click":function($event){return _vm.editCostCodes(item)}}})]}},(_vm.isSuperAdmin)?{key:"rowActions",fn:function(ref){
                        var item = ref.item;
return [_c('table-actions',{attrs:{"item":item,"update-table-items":_vm.updateTableItems}})]}}:null],null,true)})]}}])}),_c('multi-cost-code-edit-modal',{attrs:{"item":_vm.editItem},on:{"close":function($event){_vm.editItem = null},"saved":_vm.updateTableItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }