import ObjectMapper from 'object-mapper'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

export default {
    data() {
        return {
            mapOrder: {
                created_at: 'createdAt',
                status: 'status',
                identifier: 'identifier',
                delivery_job_id: 'deliveryJob.id',
                is_delivery_processed: 'isDeliveryProcessed',
                sent_ratio: 'sentRatio',
                updated_at: 'updatedAt',
                preparation_confirmed: 'preparationConfirmed',
                restaurant_id: 'restaurant',
                restaurant_name: 'restaurantName',
                user_profile_id: 'userProfile.id',
                company_name: 'companyName',
                company_id: 'company',
                required_delivery_time: 'requiredDeliveryTime',
                required_starter_time: 'requiredStarterTime',
                price_sum: 'priceTotal',
                currency: 'currency',
                currency_locale: 'currencyLocale',
                cloudcanteen_id: 'cloudcanteen',
                warning: 'warning',
                warnings: 'warnings',
                is_active_for_restaurant: 'isActiveForRestaurant',
                label_codes: 'labelCodes',
                third_party_order_id: 'thirdPartyOrderId',
                third_party_tracking_url: 'thirdPartyTrackingUrl',
                third_party_code: 'thirdPartyCode',
                price_sum_firm: 'priceSumFirm',
                price_sum_user: 'priceSumUser',
                canteen_sub_orders_by_cutoff: 'canteenSubOrdersByCutoff',
            },
            mapDeliveryJob: {
                error: 'error',
                courier_status: 'courierStatus',
                driver_name: 'driverName',
                driver_phone: 'driverPhone',
                status: 'status',
                updated_at: 'updatedAt',
                eta_to_dropoff: 'etaToDropoff',
                eta_to_pickup: 'etaToPickup',
                delivery_method: 'deliveryMethod',
                courier_lat: 'courierLat',
                courier_long: 'courierLong',
                created_at: 'createAt',
                tracking_url: 'trackingUrl',
                latest_courier_error_delivery_method:
                    'latestCourierErrorDeliveryMethod',
                latest_courier_error: 'latestCourierError ',
                active_delivery_method: 'activeDeliveryMethod',
                pickup_time: 'pickupTime',
                disable_auto_create: 'disableAutoCreate',
                warning: 'warning',
                warnings: 'warnings',
                disable_stuart_delay: 'disableStuartDelay',
            },
            mapDeliveryProviderJob: {
                error: 'error',
                courier_status: 'courierStatus',
                driver_name: 'driverName',
                driver_phone: 'driverPhone',
                status: 'status',
                delivery_uid: 'deliveryUid',
                delivery_job_id: 'deliveryJob.id',
                updated_at: 'updatedAt',
                eta_to_destination: 'etaToDestination',
                eta_to_pickup: 'etaToPickup',
                delivery_method: 'deliveryMethod',
                create_at: 'createAt',
                tracking_url: 'trackingUrl',
            },
            mapCanteen: {
                cutoffTime: 'cutoffTime',
                identifier: 'identifier',
                identifierForRestaurant: 'identifierForRestaurant',
                mealCount: 'mealCount',
                meals: 'meals',
                requiredStartTime: 'requiredStartTime',
                restaurantId: 'restaurantId',
                restaurantName: 'restaurantName',
                rules: 'rules',
                startDeliveryAt: 'startDeliveryAt',
                startPrepareAt: 'startPrepareAt',
                total: 'total  ',
            },
        }
    },
    methods: {
        objectMapper(item, object, map) {
            item = cloneDeep(item)
            object = cloneDeep(object)
            map = cloneDeep(map)
            Object.entries(object).forEach(([key, value]) => {
                try {
                    object[key] = JSON.parse(value.new)
                } catch (e) {
                    object[key] = value.new
                }
            })
            return merge(item, ObjectMapper(object, map))
        },
    },
}
