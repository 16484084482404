import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'
import Store from '@/store/store'
import MapperCollection from '@/services/mapper/MapperCollection'
import MapperType from '@/services/mapper/MapperType'
import configType from '@/store/type/deliveryType'

class DeliveriesApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/delivery-provider-jobs'
    }

    getDeliveryJobBaseUrl() {
        return '/api/v1/delivery-jobs'
    }

    clone(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'clone'))
        )
    }

    get(id) {
        return ResponseHandler.handle(apiClient.get(this.getItemIri(id)))
    }

    getProviderJob(id) {
        if (!id) {
            Store.commit(configType.mutations.SET_PROVIDER_JOBS, {})
            return Promise.resolve()
        }
        return ResponseHandler.handle(
            this.list({
                'deliveryJob.orders.identifier': id,
                itemsPerPage: 99,
            }),
            configType.mutations.SET_PROVIDER_JOBS,
            undefined
        )
    }

    getDeliveryJob(id) {
        if (!id) {
            Store.commit(configType.mutations.SET_DELIVERY_JOB, {})
            return Promise.resolve()
        }
        return ResponseHandler.handle(
            apiClient.get(`${this.getDeliveryJobBaseUrl()}/${id}`),
            configType.mutations.SET_DELIVERY_JOB,
            MapperCollection.get(MapperType.DELIVERY_FORM)
        )
    }

    getTimeline(queryParams) {
        return ResponseHandler.handle(
            apiClient.get('/api/v1/delivery-job-log', {
                params: queryParams || {},
            })
        )
    }

    cancelDeliveryProviderJob(id) {
        return ResponseHandler.handle(
            apiClient.post(this.getItemIri(id, 'cancel'))
        )
    }

    sendDeliveryProviderJob(id) {
        return ResponseHandler.handle(
            apiClient.post(this.getItemIri(id, 'send'))
        )
    }

    disableStuartDelay(deliveryJobId) {
        return ResponseHandler.handle(
            apiClient.post(
                `${this.getDeliveryJobBaseUrl()}/${deliveryJobId}/disable-stuart-delay`
            )
        )
    }

    closeDeliveryProviderJob(id) {
        return ResponseHandler.handle(
            apiClient.post(this.getItemIri(id, 'close'))
        )
    }

    cancelDeliveryJob(id) {
        return ResponseHandler.handle(
            apiClient.post(`${this.getDeliveryJobBaseUrl()}/${id}/cancel`)
        )
    }

    // create delivery job
    create(entity) {
        return ResponseHandler.handle(
            apiClient.put(this.getDeliveryJobBaseUrl(), entity)
        )
    }

    updateDeliveryJob(entity) {
        return ResponseHandler.handle(
            apiClient.post(
                `${this.getDeliveryJobBaseUrl()}/${entity.id}`,
                entity
            ),
            configType.mutations.SET_DELIVERY_JOB,
            MapperCollection.get(MapperType.DELIVERY_FORM)
        )
    }

    sendOutDeliveryJob(id) {
        return ResponseHandler.handle(
            apiClient.post(`${this.getDeliveryJobBaseUrl()}/${id}/send-out`)
        )
    }

    createAndQueueDeliveryProviderJob(entity, id) {
        return ResponseHandler.handle(
            apiClient.put(
                `${this.getDeliveryJobBaseUrl()}/${id}/create-and-queue-provider-job`,
                entity
            )
        )
    }

    preCreateDeliveryCC(id, deliveryMethod) {
        return ResponseHandler.handle(
            apiClient.post(
                `${this.getDeliveryJobBaseUrl()}/pre-create-for/cloud-canteen/${id}`,
                {
                    deliveryMethod: deliveryMethod,
                }
            )
        )
    }

    preCreateDeliveryResidentialCC(id, deliveryMethod) {
        return ResponseHandler.handle(
            apiClient.post(
                `${this.getDeliveryJobBaseUrl()}/pre-create-for/residential-canteen/${id}`,
                {
                    deliveryMethod: deliveryMethod,
                }
            )
        )
    }

    forceFinishDeliveryJob(id) {
        return ResponseHandler.handle(
            apiClient.post(`${this.getDeliveryJobBaseUrl()}/${id}/force-finish`)
        )
    }

    getPedalme(lat, lon) {
        return ResponseHandler.handle(
            apiClient.get(`${this.getDeliveryJobBaseUrl()}/pedalme`, {
                params: {
                    lat,
                    lon,
                },
            })
        )
    }

    sendToPedalme(pickupTime, deliveryJobs, size) {
        return ResponseHandler.handle(
            apiClient.post(`${this.getDeliveryJobBaseUrl()}/send-to-pedalme`, {
                pickupTime,
                deliveryJobs,
                size,
            })
        )
    }

    removeDeliveryRuleOverrides(id) {
        return ResponseHandler.handle(
            apiClient.post(
                `${this.getDeliveryJobBaseUrl()}/${id}/remove-delivery-rule-overrides`,
                {}
            )
        )
    }
}

export default new DeliveriesApiClient()
